<template>
    <horizontal-background-image-text-cta
        class="horizontal-background-text-image-section-module"
        :title="attributes.title"
        :description="attributes.description"
        :light-text-colour="attributes.light_text_colour"
        :desktop-background="desktopBackgroundImage"
        :mobile-background="mobileBackgroundImage"
        :button="button"
        :centered="attributes.centered"
    />
</template>

<script>
    import HorizontalBackgroundImageTextCta from '../../../molecules/HorizontalBackgroundImageTextCta/HorizontalBackgroundImageTextCta';
    import formatMediaObject from '../../../../utils/formatters/format-media-object';

    export default {
        name: 'horizontal-background-text-image-section-module',

        components: { HorizontalBackgroundImageTextCta },

        props: {
            attributes: {
                type: Object,
                required: true,
                default: () => ({}),
            },
        },

        computed: {
            /**
             * Get desktop background image.
             *
             * @returns {object}
             */
            desktopBackgroundImage() {
                return formatMediaObject.call(this, this.attributes.desktop_background_image);
            },

            /**
             * Get mobile background image.
             *
             * @returns {object}
             */
            mobileBackgroundImage() {
                return formatMediaObject.call(this, this.attributes.mobile_background_image);
            },

            /**
             * Get button.
             *
             * @returns {object}
             */
            button() {
                return {
                    name: this.attributes.button_text,
                    to: this.attributes.button_link
                        ?.data
                        ?.attributes
                        ?.uri,
                    href: this.attributes.button_link_external,
                };
            },
        },
    };
</script>
