<template>
    <fade-up-animation :element="() => $refs.section">
        <base-banner
            class="horizontal-background-image-text-cta"
            :class="{ 'light': lightTextColour }"
            :mobile-background="mobileBackground"
            :desktop-background="desktopBackground"
            tag="section"
            ref="section"
        >
            <b-container
                class="content-container"
                :class="{ centered }"
                v-test:contentContainer
            >
                <mobile-gutter>
                    <h2 class="title" v-test:title>{{ title }}</h2>

                    <p
                        v-if="description"
                        class="description"
                        v-test:description
                    >
                        {{ description }}
                    </p>

                    <b-button
                        v-if="(button.to || button.href) && button.name"
                        class="button"
                        :to="button.to"
                        :href="button.href"
                        variant="primary"
                        v-test:button
                    >
                        {{ button.name }}
                    </b-button>
                </mobile-gutter>
            </b-container>
        </base-banner>
    </fade-up-animation>
</template>

<script>
    import BaseBanner from '../BaseBanner/BaseBanner';

    export default {
        name: 'horizontal-background-image-text-cta',

        components: { BaseBanner },

        props: {
            desktopBackground: {
                type: Object,
                required: true,
            },

            mobileBackground: {
                type: Object,
                default: () => ({}),
            },

            title: {
                type: String,
                default: '',
            },

            description: {
                type: String,
                default: '',
            },

            lightTextColour: {
                type: Boolean,
                default: false,
            },

            button: {
                type: Object,
                default: () => ({}),
            },

            centered: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .horizontal-background-image-text-cta {
        position: relative;
        padding: 30px 0;
        min-height: 245px;

        @include media-breakpoint-up(md) {
            padding: 80px 0;
            min-height: 470px;
        }
    }

    .content-container {
        position: relative;

        &.centered {
            text-align: center;

            .title {
                padding-bottom: rem(20px);

                @include media-breakpoint-up(sm) {
                    max-width: 300px;
                    margin: 0 auto;
                }

                @include media-breakpoint-up(md) {
                    max-width: 750px;
                }

                &:last-child {
                    padding-bottom: 10%;
                }
            }

            .description {
                margin-left: auto;
                margin-right: auto;
                font-size: rem(24px);

                @include media-breakpoint-up(md) {
                    max-width: 80%;
                    font-size: rem(40px);
                }
            }
        }
    }

    .title {
        font-weight: bold;
        white-space: pre-line;
        margin-bottom: 0.75em;
        font-size: rem(32px);
        line-height: rem(36px);

        @include media-breakpoint-up(md) {
            font-size: rem(64px);
            line-height: rem(80px);
            margin-bottom: 0.5rem;
        }

        &:last-child {
            margin-bottom: 0;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }
    }

    .description {
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            max-width: 420px;
        }
    }

    .light {
        .title,
        .description {
            color: $white;
        }
    }
</style>
