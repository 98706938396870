<template>
    <component :is="tag" class="base-banner">
        <lazy-background
            :src="mobileBackground.src ? mobileBackground.src : desktopBackground.src"
            :alt="mobileBackground.alt ? mobileBackground.alt : desktopBackground.alt"
            class="mobile-background"
            v-test:mobileBackground
        />

        <lazy-background
            :src="desktopBackground.src"
            :alt="desktopBackground.alt"
            class="desktop-background"
            v-test:desktopBackground
        />

        <div class="slot-content">
            <slot />
        </div>
    </component>
</template>

<script>
    export default {
        name: 'base-banner',

        props: {
            mobileBackground: {
                type: Object,
                default: () => ({}),
            },

            desktopBackground: {
                type: Object,
                default: () => ({}),
            },

            tag: {
                type: String,
                default: 'div',
            },
        },
    };
</script>

<style lang="scss" scoped>
    .base-banner {
        position: relative;
    }

    ::v-deep .lazy-background {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    .mobile-background {
        display: block;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    .desktop-background {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    .slot-content {
        position: relative;
    }
</style>
